import React from 'react';
import { Helmet } from 'react-helmet';

const PageHelmet = props => {
	return (
		<>
			<Helmet>
				<title>{props.pageTitle ? props.pageTitle : 'Cable Mundo'} </title>
			</Helmet>
		</>
	);
};

export default PageHelmet;
