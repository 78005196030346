import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CircularProgressWithLabel = props => {
	return (
		<Box
			className="row justify-content-center align-items-center minh-100"
			style={{ height: '100vh' }}
			sx={{ position: 'relative', display: 'inline-flex' }}
		>
			<CircularProgress variant="determinate" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Typography variant="caption" component="div" color="text.secondary">
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
	);
};

CircularProgressWithLabel.propTypes = {
	/**
	 * El valor del indicador de progreso para la variante determinada.
	 * Valor entre 0 y 100.
	 * @default 0
	 */
	value: PropTypes.number.isRequired
};

const ProgressBar = () => {
	const [progress, setProgress] = useState(10);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 10));
		}, 800);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return <CircularProgressWithLabel value={progress} />;
};
export default ProgressBar;
