import React, { useEffect, useState } from 'react';
import { MemoryRouter } from 'react-router-dom';
import { FiChevronUp } from 'react-icons/fi';
import { isDesktop } from 'react-device-detect';
import clsx from 'clsx';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../components/common/Helmet';
import Breadcrumb from '../elements/common/Breadcrumb';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import fetchData from '../services/fetch';
import ProgressBar from '../elements/Progress/ProgressCircle';
import ServiceList from '../elements/portfolio/ServiceList';

const Service = () => {
	const [listServiciosGenerales, setListServiciosGenerales] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sede, setSede] = useState('');
	// efecto para optener el resumen del servicio general
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getServiciosGenerales = async () => {
			// Iniciamos carga de la tabla
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/serviciosGenerales?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListServiciosGenerales(result.data.list);
			}
			// // Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		getServiciosGenerales();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, []);

	const onChange = evt => {
		setSede(evt.target.value);
		// console.log(sede);
	};
	return (
		<>
			{loading && (
				<div className="px-20 py-52">
					<ProgressBar />
				</div>
			)}
			<>
				<PageHelmet pageTitle="Servicios" />
				{/* Start Header Area  */}
				<MemoryRouter>
					<Header logo="light" color="color-white" />
				</MemoryRouter>
				{/* End Header Area  */}

				{/* Start Breadcrump Area */}
				<Breadcrumb title="Servicios" />
				{/* End Breadcrump Area */}
				<div className={clsx('derecha ')}>
					<span style={{ marginTop: '60px', fontSize: '20px' }}>
						<b className="mr--10">Selecciona la sede:</b>
					</span>
					<select onChange={onChange} style={{ width: '250px', marginTop: '20px' }}>
						<option value="TODOS">TODOS</option>
						<option value="MOYOBAMBA">MOYOBAMBA</option>
						<option value="BAGUA GRANDE">BAGUA GRANDE</option>
						<option value="TARAPOTO">TARAPOTO</option>
						<option value="SORITOR">SORITOR</option>
						<option value="JEPELACIO">JEPELACIO</option>
					</select>
				</div>
				{/* Start Service Area */}
				<div
					className={clsx(
						'portfolio-area pb--0  mrl--10 prl--10 bg_image bg_image--3',
						isDesktop && 'pt--80'
					)}
				>
					<div className="portfolio-sacousel-inner">
						{listServiciosGenerales.map((resumen, index) => (
							<div className="container pt--30 " key={index}>
								<div className="row centrar ">
									<div className="col-lg-12">
										<div className="section-title text-center service-style--3 mb--15">
											<h2 className="title">{resumen.titulo}</h2>
											<p>{resumen.descripcion}</p>
										</div>
									</div>
								</div>

								<div className="row">
									<ServiceList
										// ServiceListContent={listServicios}
										styevariation="text-center mt--40"
										column="col-lg-4 col-md-6 col-sm-6 col-12"
										categoria={resumen.titulo}
										filtro={sede}
										// item="6"
									/>
								</div>
							</div>
						))}
					</div>
				</div>
				{/* End Service Area */}

				{/* Start Footer Style  */}
				<Footer />

				{/* Start Back To Top */}
				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>
				{/* End Back To Top */}
			</>
		</>
	);
};
export default Service;
