import React from "react";
import { FiX, FiMenu } from "react-icons/fi";

const Header = (props) => {
  const { color = "default-color" } = props;

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  // submenus
  const elements = document.querySelectorAll(".has-droupdown > a");

  for (const i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  const logoUrl = (
    <img
      src="/assets/images/logo.png"
      alt="Cable Mundo"
      width="80px"
      height=""
    />
  );

  return (
    <header
      className={`header-area formobile-menu header--transparent ${color}`}
    >
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <a href="/">{logoUrl}</a>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li>
                <a href="/"> Inicio</a>
              </li>
              <li>
                <a href="/servicios"> Servicios</a>
              </li>

              <li className="has-droupdown">
                <a href="#!" onClick={(e) => e.preventDefault()}>
                  Programación
                </a>
                <ul className="submenu">
                  <li>
                    <a href="/programacionMoyobamba">Moyobamba</a>
                  </li>
                  <li>
                    <a href="/programacionBagua">Bagua Grande</a>
                  </li>
                  <li>
                    <a href="/programacionTarapoto">Tarapoto</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/contactenos"> Contactanos</a>
              </li>
              <li>
                <a href="/acerca"> Acerca de la empresa</a>
              </li>
            </ul>
          </nav>

          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span
              onClick={menuTrigger}
              onKeyPress={menuTrigger}
              role="button"
              tabIndex={-1}
              className="menutrigger text-white"
            >
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span
              onClick={CLoseMenuTrigger}
              onKeyPress={CLoseMenuTrigger}
              role="button"
              tabIndex={-1}
              className="closeTrigger"
            >
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
