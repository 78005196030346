/*******************************************************************************************************/
// Importamos las dependencias //

/*******************************************************************************************************/
import Swal2 from "sweetalert2";
/*******************************************************************************************************/
// Definimos los ajustes de la aplicación //

/*******************************************************************************************************/
// Url Base de la App

export var appBaseUrl = process.env.REACT_APP_CM_URL || ""; // Url Base de la API o endpoint de los servicios

export var apiBaseUrl = process.env.REACT_APP_API_CM_URL || ""; // Idioma por defecto de la aplicación

export var language = "es"; // Zona horaria de la aplicación

export var timeZone = "America/Lima"; // Habilita los scroll bars personalizados

export var customScrollbars = true; // Habilita las animaciones

export var animations = true; // Configurción de los mensajes de alerta clásicos

export var Swal = Swal2.mixin({
  showConfirmButton: false,
  heightAuto: false,
  padding: "3em",
  background: "#fff url(/assets/images/bgalert.jpg)",
  width: 400,
  customClass: {
    confirmButton: "btn-swal2",
    denyButton: "btn-swal2"
  }
}); // Configuración de los mensajes de alerta tipo Toast

export var Toast = Swal2.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  background: "#43A047",
  iconColor: "#ffffff",
  customClass: {
    title: "text-white"
  },
  didOpen: function didOpen(toast) {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
}); // Api Key de Google Maps

export var mapApiKey = process.env.REACT_APP_MAP_API_KEY || "";