import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../components/header/Header';

const Error404 = () => {
	return (
		<>
			{/* Start Header Area  */}
			<MemoryRouter>
				<Header logo="light" color="color-white" />
			</MemoryRouter>
			{/* End Header Area  */}
			{/* Start Page Error  */}
			<div className="error-page-inner bg_color--4">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="inner">
								<h1 className="title theme-gradient">404!</h1>
								<h3 className="sub-title">Página no encontrada</h3>
								<span>No se pudo encontrar la página que estaba buscando.</span>
								<div className="error-button">
									<a className="rn-button-style--2 btn-solid" href="/">
										Volver a la página de inicio
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Page Error  */}

			{/* Start Back To Top */}
			<div className="backto-top">
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>
			{/* End Back To Top */}
		</>
	);
};
export default Error404;
