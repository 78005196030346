import React, { useState } from 'react';

const FormContact = () => {
	const [constact, setContact] = useState({
		rnName: '',
		rnEmail: '',
		rnSubject: '',
		rnMessage: ''
	});

	return (
		<div className="contact-form--1">
			<div className="container">
				<div className="row row--35 align-items-start">
					<div className="col-lg-6 order-2 order-lg-1">
						<div className="section-title text-left mb--50">
							<h2 className="title">Contactanos</h2>
							<p className="description">
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto cupiditate aperiam
								neque.
							</p>
						</div>
						<div className="form-wrapper">
							<form>
								<label htmlFor="item01">
									<input
										type="text"
										name="name"
										id="item01"
										value={constact.rnName}
										onChange={e => {
											setContact({ rnName: e.target.value });
										}}
										placeholder="Your Name *"
									/>
								</label>

								<label htmlFor="item02">
									<input
										type="text"
										name="email"
										id="item02"
										value={constact.rnEmail}
										onChange={e => {
											setContact({ rnEmail: e.target.value });
										}}
										placeholder="Your email *"
									/>
								</label>

								<label htmlFor="item03">
									<input
										type="text"
										name="subject"
										id="item03"
										value={constact.rnSubject}
										onChange={e => {
											setContact({ rnSubject: e.target.value });
										}}
										placeholder="Write a Subject"
									/>
								</label>
								<label htmlFor="item04">
									<textarea
										type="text"
										id="item04"
										name="message"
										value={constact.rnMessage}
										onChange={e => {
											setContact({ rnMessage: e.target.value });
										}}
										placeholder="Your Message"
									/>
								</label>
								<button
									className="rn-button-style--2 btn-solid"
									type="submit"
									value="submit"
									name="submit"
									id="mc-embedded-subscribe"
								>
									Submit
								</button>
							</form>
						</div>
					</div>
					<div className="col-lg-6 order-1 order-lg-2">
						<div className="thumbnail mb_md--30 mb_sm--30">
							<img src="/assets/images/about/about-6.jpg" alt="trydo" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default FormContact;
