import React, { useState, useEffect } from 'react';
import fetchData from '../../services/fetch';
import ProgressBar from '../Progress/ProgressCircle';

const CallAction = () => {
	const [listMotivacion, setListMotivacion] = useState([]);
	const [loading, setLoading] = useState(true);
	// Efecto para obtener la motivacion
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener la motivacion
		const getMotivacion = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/inicio/motivacion?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListMotivacion(result.data.list);
			}
			// Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos  los testimonios de los abonados
		getMotivacion();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListMotivacion]);
	// console.log(listMotivacion);
	return (
		<>
			{/* cargar loading */}
			{loading ? (
				<div className="px-20 py-52">
					<ProgressBar />
				</div>
			) : (
				<div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								{listMotivacion.map((motivacion, index) => (
									<div className="inner text-center" key={index}>
										<span>{motivacion.subtitulo}</span>
										<h2>{motivacion.titulo}</h2>
										<a className="rn-button-style--2" href={motivacion.linkBoton}>
											<span>{motivacion.textoBoton}</span>
										</a>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default CallAction;
