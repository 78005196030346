/* eslint no-use-before-define: 0 */ //
import React, { useState, useEffect } from 'react';
import { MemoryRouter } from 'react-router-dom';
import clsx from 'clsx';
import { FiHeadphones, FiMail, FiMapPin, FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../components/common/Helmet';
import FormContact from '../elements/contact/FormContact';
import Brand from '../elements/Brand';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import fetchData from '../services/fetch';
import ProgressBar from '../elements/Progress/ProgressCircle';

// const tmpConsoleLog = console.warn;
// function deleteWarn(msg) {
// 	if (
// 		msg ===
// 		'google.maps.event.addDomListener() is deprecated, use the standard addEventListener() method instead: https://developer.mozilla.org/docs/Web/API/EventTarget/addEventListener\nThe feature will continue to work and there is no plan to decommission it.'
// 	) {
// 		tmpConsoleLog(msg);
// 		console.warn = () => {};
// 	}
// }
// console.warn = deleteWarn();
const Contact = () => {
	// Instanciamos los estilos
	// const styles = useStyles();
	const [loading, setLoading] = useState(true);
	const [banner, setBanner] = useState([]);
	const [infoContactos, setInfoContactos] = useState([]);
	const [sede, setSede] = useState(null);
	console.log('hola');
	// banner
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los banner
		const getBanner = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los banner con fetch
			const result = await fetchData(`web/contactos/banner?page=1&pageSize=10`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setBanner(result.data.list);
			}
			// // Finalizamos carga
			setLoading(false);
		};

		// Obtenemos los banner
		getBanner();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, []);
	// console.log(banner);

	// info contactos
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los info contactos
		const getInfoContactos = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los info contactos con fetch
			const result = await fetchData(`web/contactos/info?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setInfoContactos(result.data.list);
			}
			// // Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los info contactos
		getInfoContactos();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, []);
	// console.log(infoContactos);
	const onChange = evt => {
		setSede(evt.target.value);
	};

	return (
		<>
			{loading && (
				<div className="px-20 py-52">
					<ProgressBar />
				</div>
			)}
			<>
				<PageHelmet pageTitle="Contactos" />

				{/* Start Header Area  */}
				<MemoryRouter>
					<Header logo="light" color="color-white" />
				</MemoryRouter>
				{/* End Header Area  */}

				{/* Start Breadcrump Area */}
				{banner.map((ele, e) => (
					<div className="rn-page-title-area  pt--120 pb--190 contentContact" data-black-overlay="6" key={e}>
						<img src={ele.img} className="bannerContact" alt="Banner detalles" />
						<div className="container ">
							<div className="row">
								<div className="col-lg-12">
									<div className="rn-page-title text-center pt--100">
										<h2 className="title theme-gradient"> {ele.titulo}</h2>
										<p>{ele.descripcion}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}

				{/* End Breadcrump Area */}
				{/* Start Filtros Sede */}
				<div className={clsx('derecha ')}>
					<span style={{ marginTop: '60px', fontSize: '20px' }}>
						<b className="mr--10">Selecciona la sede:</b>
					</span>
					<select onChange={onChange} style={{ width: '250px', marginTop: '20px' }}>
						<option value="TODOS">TODOS</option>
						<option value="MOYOBAMBA">MOYOBAMBA</option>
						<option value="BAGUA GRANDE">BAGUA GRANDE</option>
						<option value="TARAPOTO">TARAPOTO</option>
						<option value="SORITOR">SORITOR</option>
						<option value="JEPELACIO">JEPELACIO</option>
					</select>
				</div>
				{/* End Filtros Sede */}
				{/* Start Contact Top Area  */}
				<div className="rn-contact-top-area ptb--60 bg_color--5">
					{infoContactos.map((ele, index) => {
						return (
							(sede === 'null' || sede === null
								? ele.local.nombre === 'MOYOBAMBA' ||
								  ele.local.nombre === 'BAGUA GRANDE' ||
								  ele.local.nombre === 'TARAPOTO' ||
								  ele.local.nombre === 'SORITOR' ||
								  ele.local.nombre === 'JEPELACIO'
								: sede === 'TODOS'
								? ele.local.nombre === 'MOYOBAMBA' ||
								  ele.local.nombre === 'BAGUA GRANDE' ||
								  ele.local.nombre === 'TARAPOTO' ||
								  ele.local.nombre === 'SORITOR' ||
								  ele.local.nombre === 'JEPELACIO'
								: ele.local.nombre === sede) && (
								<div key={index} className="mt--20 pt--20">
									<h3>{ele.local.nombre}</h3>
									<div className="container">
										<div className="row">
											<div className="col-lg-4 col-md-6 col-sm-6 col-12">
												<div className="rn-address">
													<div className="icon">
														<FiHeadphones />
													</div>
													<div className="inner">
														<h4 className="title">Números de teléfono</h4>
														{ele.telefono.map((value, i) => (
															<p key={i}>
																<a href={`tel:${value}`}>{value}</a>
															</p>
														))}
													</div>
												</div>
											</div>

											<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
												<div className="rn-address">
													<div className="icon">
														<FiMail />
													</div>
													<div className="inner">
														<h4 className="title">Correos</h4>
														{ele.correo.map((value, i) => (
															<p key={i}>
																<a href={`mailto:${value}`}>{value}</a>
															</p>
														))}
													</div>
												</div>
											</div>

											<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
												<div className="rn-address">
													<div className="icon">
														<FiMapPin />
													</div>
													<div className="inner">
														<h4 className="title">Ubicacion</h4>
														<p>{ele.ubicacion}</p>
													</div>
												</div>
											</div>
											{/* End Single Address  */}
										</div>
									</div>
								</div>
							)
						);
					})}
				</div>
				{/* End Contact Top Area  */}

				{/* Start Contact Page Area  */}
				<div className="rn-contact-page ptb--60 bg_color--1">
					<FormContact />
				</div>
				{/* End Contact Page Area  */}

				{/* Start Contact Map  */}
				<div className="google-map-code">
					{sede === 'JEPELACIO' ? (
						<iframe
							title="Mapa de JEPELACIO"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.973935441214!2d-76.91689636385901!3d-6.107587799710771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf55003f5b137f72a!2zNsKwMDYnMjcuMSJTIDc2wrA1NCc1Ni45Ilc!5e1!3m2!1ses-419!2spe!4v1660772833019!5m2!1ses-419!2spe"
							width="100%"
							height="600"
							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen={false}
							aria-hidden="false"
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					) : sede === 'MOYOBAMBA' ? (
						<iframe
							title="Mapa de MOYOBAMBA"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2359.218501379118!2d-76.97303560228518!3d-6.033554092051754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91b73b03ecf75d93%3A0xd7028e412bbeb77f!2sTV-CABLE%20%22Cable%20Mundo%22!5e0!3m2!1ses-419!2spe!4v1660585403546!5m2!1ses-419!2spe"
							width="100%"
							height="600"
							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen={false}
							aria-hidden="false"
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					) : sede === 'TARAPOTO' ? (
						<iframe
							title="Mapa de TARAPOTO"
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1178.5844331634678!2d-76.35924436188832!3d-6.48749177830984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ba0c061b6bc8c3%3A0x4f210e96569355b6!2sCable%20Mundo!5e0!3m2!1ses-419!2spe!4v1660772623547!5m2!1ses-419!2spe"
							width="100%"
							height="600"
							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen={false}
							aria-hidden="false"
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					) : sede === 'BAGUA GRANDE' ? (
						<iframe
							title="Mapa de BAGUA GRANDE"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.7132424894608!2d-78.44436498586683!3d-5.754352858435429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91b44fbc9f65fd4f%3A0x187c23e93ab4ef1!2sPlaza%20de%20Armas%20Bagua%20Grande!5e0!3m2!1ses-419!2spe!4v1660772424134!5m2!1ses-419!2spe"
							width="100%"
							height="600"
							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen={false}
							aria-hidden="false"
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					) : sede === 'SORITOR' ? (
						<iframe
							title="Mapa de SORITOR"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1402.5218727206677!2d-77.10280789099399!3d-6.139984099339854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91b72ecb34376e23%3A0x344461f70b946700!2sTV%20Cable%20Mundo.!5e0!3m2!1ses-419!2spe!4v1660772741519!5m2!1ses-419!2spe"
							width="100%"
							height="600"
							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen={false}
							aria-hidden="false"
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					) : (
						<iframe
							title="Mapa Inicial"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2359.218501379118!2d-76.97303560228518!3d-6.033554092051754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91b73b03ecf75d93%3A0xd7028e412bbeb77f!2sTV-CABLE%20%22Cable%20Mundo%22!5e0!3m2!1ses-419!2spe!4v1660585403546!5m2!1ses-419!2spe"
							width="100%"
							height="600"
							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen={false}
							aria-hidden="false"
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					)}

					{/* <iframe
						title="Mapa de Cable Mundo Moyobamba"
						width="100%"
						height="600"
						frameBorder="0"
						style={{ border: 0 }}
						allowFullScreen={false}
						aria-hidden="false"
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
						src="https://www.google.com/maps/embed/v1/place?q=X28H%2BXFR%2C%20Oscar%20Reyes%20Benavides%2C%20Moyobamba%2022001&key=AIzaSyCgZ09472yqX2s1ja8tVwlgJSgxVu4rEms"
					/> */}
				</div>
				{/* End Contact Map  */}

				{/* Start Brand Area */}
				<div className="row">
					<div className="col-lg-12 mt--40 mb--80">
						<Brand branstyle="branstyle--2" />
					</div>
				</div>
				{/* End Brand Area */}

				{/* Start Back To Top */}
				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>
				{/* End Back To Top */}

				<Footer />
				{}
			</>
		</>
	);
};

export default Contact;
