import React, { useState, useEffect } from 'react';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import clsx from 'clsx';
import { useParams, MemoryRouter } from 'react-router-dom';
// import ModalVideo from "react-modal-video";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import PageHelmet from '../components/common/Helmet';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import fetchData from '../services/fetch';
import Error404 from './Error404';
import ProgressBar from '../elements/Progress/ProgressCircle';

const ServiceDetails = () => {
	const textPosition = 'text-center';
	const [loading, setLoading] = useState(true);

	// Obtenemos el id del SERVICIO WEB de los parámetros de la ruta
	const { id } = useParams();

	// console.log(id);

	const [listServiciosDetalles, setListServiciosDetalles] = useState([]);
	const [listServiciosDetalleDetalles, setListServiciosDetalleDetalles] = useState([]);

	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getServiciosDetalles = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/detallesServicio/banner`);
			// console.log(result);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				const data = result.data.list.filter(ele => ele.servicioWeb._id === id && ele);
				// console.log(data);
				// Actualizamos la lista en la data local
				setListServiciosDetalles(data);
				// result.data.list.servicioWeb._id === id && result.data.list
			}
			// Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		// if (id) {
		getServiciosDetalles();
		// }

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListServiciosDetalles, id]);

	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getServiciosDetallesDetalles = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/detallesServicio/detalles`);

			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				const data = result.data.list.filter(ele => ele.servicioWeb._id === id && ele);
				// Actualizamos la lista en la data local
				setListServiciosDetalleDetalles(data);
				// result.data.list.servicioWeb._id === id && result.data.list
			}
			// Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		// if (id) {
		getServiciosDetallesDetalles();
		// }

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListServiciosDetalleDetalles, id]);
	// console.log(listServiciosDetalleDetalles);
	// console.log(listServiciosDetalles[0]);
	return (
		<>
			{loading && (
				<div className="px-20 py-52">
					<ProgressBar />
				</div>
			)}
			<>
				{/* Start Pagehelmet  */}
				<PageHelmet pageTitle="Service Details" />
				{/* End Pagehelmet  */}

				{/* Start Header Area  */}
				<MemoryRouter>
					<Header logo="light" color="color-white" />
				</MemoryRouter>
				{/* End Header Area  */}

				{listServiciosDetalles[0] !== undefined ? (
					<>
						<div className="slider-wrapper color-white">
							<div className="slider-activation slider-digital-agency">
								{listServiciosDetalles.map((ele, index) => {
									// console.log(ele);
									return (
										<div
											className="slide slide-style-2 fullscreen  d-flex align-items-center justify-content-center "
											key={index}
											data-black-overlay="5"
										>
											<img src={ele.img} alt="banner detalles" />
											<div className="container">
												<div className="row">
													<div className="col-lg-12">
														<div className={`inner ${textPosition}`}>
															<h2 className="title theme-gradient">{ele.titulo}</h2>
															{/* <p>{ele.servicioWeb.}</p> */}
															<p className="description">{ele.descripcion}</p>
															<p className="servicio theme-gradient">
																{ele.servicioWeb.servicio.nombre}
															</p>
															<div className="clearfix" />
															<p className="sede">
																{ele.servicioWeb.servicio.barrio.local.nombre} -{' '}
																{ele.servicioWeb.servicio.barrio.nombre}
															</p>

															<p className="costo">
																<b> S/. {ele.servicioWeb.servicio.costo}</b>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						{/* </div> */}
						{/* End Breadcrump Area */}

						{/* Start Page Wrapper */}
						<div className="rn-service-details ptb--120 bg_color--1">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="service-details-inner">
											<div className="inner">
												{/* Start detalles del servicio */}
												{listServiciosDetalleDetalles.map((ele, i) => {
													// console.log(ele);
													return (
														<div key={i}>
															<h3
																className={clsx(
																	'title tituloDetalles align-items-center',
																	isDesktop && 'font--35',
																	isTablet && 'font--32',
																	isMobile && 'font--25'
																)}
															>
																{ele.titulo}
															</h3>
															<div className="row sercice-details-content pb--80 align-items-center">
																<div className="col-lg-6 col-12">
																	<div className="thumb">
																		<img
																			className="w-100"
																			src={ele.img}
																			alt="Imagen Servicio"
																		/>
																	</div>
																</div>
																<div className="col-lg-6 col-12">
																	<div className="details mt_md--30 mt_sm--30">
																		{ele.descripcion.map((val, a) => (
																			<p key={a}>{val}</p>
																		))}

																		<h4 className="title">{ele.subtitulo}</h4>
																		<ul className="liststyle">
																			{ele.items.map((val, e) => (
																				<li key={e}>{val}</li>
																			))}
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													);
												})}

												{/* End Single Area */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* End Page Wrapper */}

						{/* Start Back To Top */}
						<div className="backto-top">
							<ScrollToTop showUnder={160}>
								<FiChevronUp />
							</ScrollToTop>
						</div>
						{/* End Back To Top */}
					</>
				) : (
					<Error404 />
				)}
				<Footer />
			</>
		</>
	);
};
export default ServiceDetails;
