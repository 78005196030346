import React, { useState, useEffect } from 'react';
import { MemoryRouter } from 'react-router-dom';
// import CounterOne from "../elements/counters/CounterOne";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import fetchData from '../services/fetch';
import Brand from '../elements/Brand';
import Testimonial from '../elements/Testimonial';
import Breadcrumb from '../elements/common/Breadcrumb';
import PageHelmet from '../components/common/Helmet';
import Footer from '../components/footer/Footer';
import ProgressBar from '../elements/Progress/ProgressCircle';
import Header from '../components/header/Header';

const About = () => {
	const [loading, setLoading] = useState(true);
	const [listAcerca, setListAcerca] = useState([]);
	const [listQuinesSomos, setListQuinesSomos] = useState([]);
	const [listResumenTrabajadoresDestacados, setListResumenTrabajadoresDestacados] = useState([]);
	const [listTrabajadoresDestacados, setListTrabajadoresDestacados] = useState([]);

	// efecto para optener el acerca de la empresa
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getAcerca = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/acerca/general?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListAcerca(result.data.list);
			}
			// // Finalizamos carga
			setLoading(false);
		};

		// Obtenemos los servicios
		getAcerca();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, []);
	// console.log(listQuinesSomos);

	// efecto para optener el resumen de trabajadores destacados
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getResumenTrabajadoresDestacaddos = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/acerca/resumenEquipo?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListResumenTrabajadoresDestacados(result.data.list);
			}
			// // Finalizamos carga
			setLoading(false);
		};

		// Obtenemos los servicios
		getResumenTrabajadoresDestacaddos();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, []);
	// console.log(listResumenTrabajadoresDestacados);

	// efecto para optener el resumen de trabajadores destacados
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getTrabajadoresDestacaddos = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/acerca/equipoCalificado?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListTrabajadoresDestacados(result.data.list);
			}
			// // Finalizamos carga
			setLoading(false);
		};

		// Obtenemos los servicios
		getTrabajadoresDestacaddos();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, []);

	// console.log(listTrabajadoresDestacados);

	// efecto para optener quines somos
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getAcerca = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/acerca/quienesSomos?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListQuinesSomos(result.data.list);
			}
			// // Finalizamos carga
			setLoading(false);
		};

		// Obtenemos los servicios
		getAcerca();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, []);
	// console.log(listAcerca);

	return (
		<>
			{loading && (
				<div className="px-20 py-52">
					<ProgressBar />
				</div>
			)}{' '}
			<>
				<PageHelmet pageTitle="Acerca de la empresa" />

				{/* Start Header Area  */}
				<MemoryRouter>
					<Header logo="light" color="color-white" />
				</MemoryRouter>
				{/* End Header Area  */}
				{/* Start Breadcrump Area */}
				<Breadcrumb title="Acerca de la Empresa" />
				{/* End Breadcrump Area */}

				{/* Start About Area */}
				<div className="about-area ptb--120  bg_color--1">
					<div className="about-wrapper">
						<div className="container">
							{listAcerca.map((acerca, index) => (
								<div className="row row--35" key={index}>
									<div className="col-lg-5">
										<div className="thumbnail">
											<img className="w-100" src={acerca.img} alt="Imagen de la empresa" />
										</div>
									</div>
									<div className="col-lg-7 mt--60">
										<div className="about-inner inner">
											<div className="section-title">
												{/* <h2 className="title">{acerca.titulo}</h2> */}
												<b>
													<p
														// className="description"
														style={{
															textAlign: 'center ',
															width: '100%',
															fontSize: '20px'
														}}
													>
														{acerca.descripcion}
													</p>
												</b>
											</div>
											<div className="row mt--30">
												<div className="col-lg-12 col-md-12 col-sm-12 col-12">
													{listQuinesSomos.map((ele, i) => (
														<div className="about-us-list" key={i}>
															<h3 className="title">{ele.titulo}</h3>
															{ele.descripcion.map((item, indice) => (
																<p key={indice}>{item}</p>
															))}
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				{/* End About Area */}

				{/* Start CounterUp Area */}
				{/* <div className="rn-counterup-area pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h3 className="fontWeight500">Our Fun Facts</h3>
              </div>
            </div>
          </div>
          <CounterOne />
        </div>
      </div> */}
				{/* End CounterUp Area */}

				{/* Start Finding Us Area  */}
				{/* <div className="rn-finding-us-area rn-finding-us bg_color--1">
        <div className="inner">
          <div className="content-wrapper">
            <div className="content">
              <h4 className="theme-gradient">Find Your Work Now</h4>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that.
              </p>
              <a className="rn-btn btn-white" href="/about">
                Get Started
              </a>
            </div>
          </div>
          <div className="thumbnail">
            <div className="image">
              <img
                src="/assets/images/about/finding-us-01.png"
                alt="Finding Images"
              />
            </div>
          </div>
        </div>
      </div> */}
				{/* End Finding Us Area  */}

				{/* Start Team Area  */}
				<div className="rn-team-area bg_color--1 ptb--60">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								{listResumenTrabajadoresDestacados.map((ele, index) => (
									<div className="section-title service-style--3 text-center mb--25" key={index}>
										<h2 className="title">{ele.titulo}</h2>
										<p>{ele.descripcion}</p>
									</div>
								))}
							</div>
						</div>
						<div className="row">
							{listTrabajadoresDestacados.map((ele, index) => (
								<div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
									<div className="team">
										<div className="thumbnail">
											<img
												className="w-100"
												src={ele.img}
												alt="Blog Images"
											// height="665px"
											/>
										</div>
										<div className="content">
											<h4 className="title">{ele.nombre}</h4>
											<p className="designation">{ele.cargo}</p>
										</div>
										<ul className="social-icon">
											<li>
												<a href={ele.facebook}>
													<FacebookIcon />
												</a>
											</li>
											<li>
												<a href={ele.linkedinIn}>
													<LinkedInIcon />
												</a>
											</li>
											<li>
												<a href={`mailto:${ele.gmail}`}>
													<EmailIcon />
												</a>
											</li>
										</ul>
									</div>
								</div>
							))}
							{/* End Single Team  */}
						</div>
					</div>
				</div>
				{/* End Team Area  */}

				{/* Start Testimonial Area */}
				<div className="rn-testimonial-area bg_color--5 ptb--120">
					<div className="container">
						<Testimonial />
					</div>
				</div>
				{/* End Testimonial Area */}

				{/* Start Brand Area */}
				<div className="row">
					<div className="col-lg-12 mt--40 mb--80">
						<Brand branstyle="branstyle--2" />
					</div>
				</div>
				{/* End Brand Area */}

				{/* Start Back To Top */}
				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>
				{/* End Back To Top */}

				<Footer />
			</>

		</>
	);
};
export default About;
