import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Rutas from './routes';

function App() {
	return (
		<div className="App">
			<BrowserRouter basename="/">
				<Rutas />
			</BrowserRouter>
		</div>
	);
}

export default App;
