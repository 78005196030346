import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

// import "bootstrap/dist/js/bootstrap.bundle.min";
// Create Import File
import './index.scss';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
