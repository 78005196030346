import React, { useEffect, useState, Fragment } from 'react';
import { MemoryRouter } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import Slider from 'react-slick';
import { FiChevronUp } from 'react-icons/fi';
import Footer from '../components/footer/Footer';
import Helmet from '../components/common/Helmet';
import Header from '../components/header/Header';
import ServiceList from '../elements/service/ServiceList';
import PortfolioList from '../elements/portfolio/PortfolioList';
import Tab from '../elements/tab/Tab';
import Testimonial from '../elements/Testimonial';
import Brand from '../elements/Brand';
import CallAction from '../elements/callaction/CallAction';
import fetchData from '../services/fetch';
import ProgressBar from '../elements/Progress/ProgressCircle';
import ModalLoad from './ModalLoad';

const slideSlick = {
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	arrows: true,
	fade: true,
	easing: 'fade',
	adaptiveHeight: true
	// className: "fullscreen",
};

const Home = () => {
	const textPosition = 'text-center';

	const [loading, setLoading] = useState(true);
	const [listSlider, setListSlider] = useState([]);
	const [listAcerca, setListAcerca] = useState([]);
	const [listResumenServiciosGenerales, setListResumenServiciosGenerales] = useState([]);
	const [listResumenClientesDestacados, setListResumenClientesDestacados] = useState([]);
	const [listResumenPlanes, setListResumenPlanes] = useState([]);

	const [load, setLoad] = useState(true);

	// Efecto para obtener la lista de los sliders
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;

		// Función para obtener todas los servicios
		const getSlider = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/inicio/slider?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListSlider(result.data.list);
			}
			// Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		getSlider();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListSlider]);

	// efecto para optener el resumen del servicio general
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getResumenServiciosGenerales = async () => {
			// Iniciamos carga
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/inicio/resumenServiciosGenerales?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListResumenServiciosGenerales(result.data.list);
			}
			// // Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		getResumenServiciosGenerales();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListResumenServiciosGenerales]);
	// console.log(listResumenServiciosGenerales);

	// efecto para optener el resumen de los planes
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getResumenPlanes = async () => {
			// Iniciamos carga de la tabla
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/inicio/resumenPlanes?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListResumenPlanes(result.data.list);
			}
			// // Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		getResumenPlanes();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListResumenPlanes]);
	// console.log(listResumenPlanes);

	// efecto para optener el acerca de la empresa
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getAcerca = async () => {
			// Iniciamos carga de la tabla
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/acerca/general?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListAcerca(result.data.list);
			}
			// // Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		getAcerca();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListAcerca]);
	// console.log(listAcerca);

	// efecto para optener el resumen de los clientes
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getResumenClientes = async () => {
			// Iniciamos carga de la tabla
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/resumenClientesDestacados?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListResumenClientesDestacados(result.data.list);
			}
			// // Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		getResumenClientes();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListResumenClientesDestacados]);
	// console.log(listResumenClientesDestacados);

	// const PostList = BlogContent.slice(0, 3);
	// console.log(isLandscape());
	return (
		<>
			{/* cargar loading */}
			{loading ? (
				<div className="px-20 py-52">
					<ProgressBar />
				</div>
			) : (
				<>
					<a
						href="https://api.whatsapp.com/send?phone=+51942812059&text=Hola%21+Bienvenido+a+Cable+Mundo.+Comunicate+con+nosotros..."
						rel="noreferrer"
						noopener="true"
						className="float"
						target="_blank"
					>
						<i className="fa fa-whatsapp my-float" />
					</a>
					<Helmet pageTitle="CABLE MUNDO" />

					{/* Start Header Area  */}
					<MemoryRouter>
						<Header logo="light" color="color-white" />
					</MemoryRouter>
					{/* End Header Area  */}

					{/* Start Slider Area   */}
					<div className="slider-wrapper color-white">
						<div className="slider-activation slider-digital-agency ">
							<Slider className="rn-slick-dot  dot-light" {...slideSlick}>
								{listSlider.map((value, index) => (
									<div
										className="slide slide-style-2 fullscreen  d-flex align-items-center justify-content-center bg_image "
										key={index}
										data-black-overlay="2"
									>
										<img src={value.img} alt="Slider" />
										<div className="container">
											<div className="row">
												<div
													className="col-lg-12 "
													// style={{ textalign: "center" }}
												>
													<div
														className={`inner ${textPosition}`}
														// className="centrarSlider"
														// style={{ textPosition: "center" }}
													>
														{value.titulo ? <h1 className="title ">{value.titulo}</h1> : ''}
														{value.descripcion ? (
															<p className="description">{value.descripcion}</p>
														) : (
															''
														)}
														{value.textoBoton ? (
															<div className="slide-btn">
																<a
																	className="rn-button-style--2 btn-primary-color"
																	href={`${value.linkBoton}`}
																>
																	{value.textoBoton}
																</a>
															</div>
														) : (
															''
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</Slider>
						</div>
					</div>
					{/* End Slider Area   */}

					{/* Start resumen servicios generales area */}
					<div className="service-area pt--120 pb--50 bg_color--1">
						<div className="container">
							<div className="row">
								{listResumenServiciosGenerales.map((resumen, index) => (
									<div className="col-lg-12" key={index}>
										<div className="section-title text-center service-style--3 mb--30">
											<h2 className="title">{resumen.titulo}</h2>
											<p>{resumen.descripcion}</p>
										</div>
									</div>
								))}
							</div>
							<ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-center" />
						</div>
					</div>
					{/* Start resumen servicios generales area  */}

					{/* Start Planes Area */}
					<div className="portfolio-area ptb--120 bg_image bg_image--3">
						<div className="portfolio-sacousel-inner">
							<div className="container">
								{listResumenPlanes.map((resumen, index) => (
									<div className="row" key={index}>
										<div className="col-lg-12">
											<div className="section-title text-center service-style--3 mb--15">
												<h2 className="title">{resumen.titulo}</h2>
												<p>{resumen.descripcion}</p>
											</div>
										</div>
									</div>
								))}

								<div className="row">
									<PortfolioList
										styevariation="text-center mt--40"
										column="col-lg-4 col-md-6 col-sm-6 col-12"
										item="6"
									/>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="view-more-btn mt--60 text-center">
											<a className="rn-button-style--2 btn-solid" href="/servicios">
												<span>Ver Más</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Planes Area */}

					{/* Start About Area */}
					<div className="about-area ptb--120  bg_color--1">
						<div className="about-wrapper">
							<div className="container">
								{listAcerca.map((acerca, index) => (
									<div className="row row--35" key={index}>
										<div className="col-lg-5">
											<div className="thumbnail">
												<img className="w-100" src={acerca.img} alt="Imagen de la empresa" />
											</div>
										</div>
										<div className="col-lg-7">
											<div className="about-inner inner">
												<div className="section-title">
													<h2 className="title">{acerca.titulo}</h2>
													<b>
														<p
															// className="description"
															style={{
																textAlign: 'center ',
																width: '100%',
																fontSize: '20px'
															}}
														>
															{acerca.descripcion}
														</p>
													</b>
												</div>
												<div className="row mt--30">
													<Tab tabStyle="tab-style--1" />
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					{/* End About Area */}

					{/* Start Brand Area */}
					<div className="rn-brand-area ptb--120 bg_color--5">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									{listResumenClientesDestacados.map((resumen, index) => (
										<div className="section-title text-center service-style--3 mb--30" key={index}>
											<h2 className="title">{resumen.titulo}</h2>
											<p>{resumen.descripcion}</p>
										</div>
									))}
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 mt--40">
									<Brand branstyle="branstyle--2" />
								</div>
							</div>
						</div>
					</div>
					{/* End Brand Area */}

					{/* Start Testimonial Area */}
					<div className="rn-testimonial-area ptb--120 bg_color--5">
						<div className="container">
							<Testimonial />
						</div>
					</div>
					{/* End Testimonial Area */}

					{/* Start call To Action  */}
					<CallAction />
					{/* End call To Action  */}

					{/* Start Footer Style  */}
					<Footer />
					{/* End Footer Style  */}
					{/* Start Back To Top */}
					<div className="backto-top-home">
						<ScrollToTop showUnder={190}>
							<FiChevronUp />
						</ScrollToTop>
					</div>
					{load && <ModalLoad open={load} setOpen={setLoad} />}
				</>
			)}
			{/* End Back To Top */}
		</>
	);
};

export default Home;
