import React, { useState, useEffect } from 'react';
import fetchData from '../services/fetch';

const Brand = props => {
	const { branstyle } = props;
	const [listAbonadosJuridicos, setLisAbonadosJuridicos] = useState([]);

	// efecto para optener las imagenes de los abonados juridicos
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getAbonadosJuridicos = async () => {
			// Iniciamos carga de la tabla
			// setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/abonadosJuridocosImportantes?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setLisAbonadosJuridicos(result.data.list);
			}
			// // Finalizamos carga de la tabla
			// setLoading(false);
		};

		// Obtenemos los servicios
		getAbonadosJuridicos();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setLisAbonadosJuridicos]);
	// console.log(listAbonadosJuridicos);
	return (
		<ul className={`brand-list ${branstyle}`}>
			{listAbonadosJuridicos.map((imagen, index) => (
				<li key={index}>
					<img
						className="img-responsive center-block"
						// style={{ position: "relative", top: "-25px" }}
						// style={{ margin: "auto", display: "block" }}
						// className="img-responsive center-block"
						src={imagen.img}
						alt="Logo Images"
					/>
				</li>
			))}
		</ul>
	);
};
export default Brand;
