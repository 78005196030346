import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../views/Home';
import About from '../views/About';
import Contact from '../views/Contact';
import Error404 from '../views/Error404';
import Service from '../views/Service';
// import ServiceDetails from "../views/ServiceDetails";
import ProgramMoyo from '../views/program/ProgramMoyo';
import ProgramBagua from '../views/program/ProgramBagua';
import ProgramTarapoto from '../views/program/ProgramTarapoto';
import ServiceDetails from '../views/ServiceDetails';

const Root = () => {
	return (
		// <Router>
		<Switch>
			<Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
			<Route exact path={`${process.env.PUBLIC_URL}/servicios`} component={Service} />
			<Route exact path={`${process.env.PUBLIC_URL}/contactenos`} component={Contact} />
			<Route exact path={`${process.env.PUBLIC_URL}/acerca`} component={About} />
			{/* <Route
          exact
          path={`${process.env.PUBLIC_URL}/detalle-servicio`}
          component={ServiceDetails}
        /> */}
			<Route exact path={`${process.env.PUBLIC_URL}/programacionMoyobamba`} component={ProgramMoyo} />
			<Route exact path={`${process.env.PUBLIC_URL}/programacionBagua`} component={ProgramBagua} />
			<Route exact path={`${process.env.PUBLIC_URL}/programacionTarapoto`} component={ProgramTarapoto} />

			<Route exact path={`${process.env.PUBLIC_URL}/detallesServicio/banner/:id`} component={ServiceDetails} />

			<Route path={`${process.env.PUBLIC_URL}`} component={Error404} />
			<Route component={Error404} />
		</Switch>
		// </Router>
	);
};
export default Root;
