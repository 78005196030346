import React, { useState, useEffect } from 'react';
import fetchData from '../../services/fetch';
// import { FiWifi, FiTv, FiTool } from "react-icons/fi";

const Service = props => {
	const [listServiciosGenerales, setListServiciosGenerales] = useState([]);
	const { column } = props;
	const ServiceContent = listServiciosGenerales.slice(0, props.item);

	// Efecto para obtener la lista de los servicios generales
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getServiciosGenerales = async () => {
			// Iniciamos carga de la tabla
			// setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/serviciosGenerales?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListServiciosGenerales(result.data.list);
			}
			// // Finalizamos carga de la tabla
			// setLoading(false);
		};

		// Obtenemos los servicios
		getServiciosGenerales();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListServiciosGenerales]);
	// console.log(listServiciosGenerales);

	return (
		<>
			<div className="row">
				{ServiceContent.map((val, i) => (
					<div className={`${column} centrar`} key={i}>
						<a href="/servicios">
							<div className="service service__style--2">
								<div className="icon">
									<img width="50" height="50" src={val.icono} alt="icono" />
								</div>
								<div className="content">
									<h3 className="title">{val.titulo}</h3>
									<p>{val.descripcion}</p>
								</div>
							</div>
						</a>
					</div>
				))}
			</div>
		</>
	);
};
export default Service;
