import React, { useState, useEffect, memo } from "react";
import Slider from "react-slick";
import { MemoryRouter } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import PageHelmet from "../../components/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import fetchData from "../../services/fetch";
import { normalizar } from "../../helpers/texts";
import ProgressBar from "../../elements/Progress/ProgressCircle";
import "../../../public/assets/css/BootstrapMulti.css";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      role="button"
      tabIndex={-1}
      onClick={onClick}
      onKeyPress={onClick}
      onTouchStart={onClick}
      onTouchEnd={onClick}
    >
      <ArrowForwardIos
        style={{
          color: "#fff",
          fontSize: "30px",
          // left: "-15px",
        }}
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      role="button"
      tabIndex={-1}
      onClick={onClick}
      onKeyPress={onClick}
      onTouchStart={onClick}
      onTouchEnd={onClick}
    >
      <ArrowBackIos
        className="PrevArrow"
        style={{
          color: "#fff",
          fontSize: "30px",
          // left: "-15px",
        }}
      />
    </div>
  );
}

const slideSlick = {
  // autoplay: true,
  // play: true,
  accessibility: true,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  pauseOnHover: true,
  infinite: false,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 1,
  // initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
        arrows: false,
        // initialSlide: 0,
      },
    },
    {
      breakpoint: 912,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
        arrows: true,
        // initialSlide: 0,
        infinite: false,
        draggable: true,
      },
    },
    {
      breakpoint: 820,
      settings: {
        // accessibility: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        // initialSlide: 0,
        arrows: true,
        infinite: false,
        draggable: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        infinite: false,
        draggable: true,
      },
    },
    {
      breakpoint: 712,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        // initialSlide: 0,
        arrows: true,
        infinite: false,
        draggable: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        // initialSlide: 0,
        arrows: true,
        infinite: false,
        draggable: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        // initialSlide: 0,
        infinite: false,
        draggable: true,
      },
    },
  ],
};

const PrograBagua = () => {
  const [loading, setLoading] = useState(true);
  const [listCategoria, setListCategoria] = useState([]);
  const [listProgramacion, setListProgramacion] = useState([]);
  const sede_ = normalizar("BAGUA GRANDE");
  // categoria
  useEffect(() => {
    // Estado inicial de montaje
    let mounted = true;
    // Función para obtener todas las categorias
    const getCategoria = async () => {
      // Iniciamos carga
      setLoading(true);
      // Obtenemos la lista de las categorias con fetch
      const result = await fetchData(
        `web/programacion/categoria?page=1&pageSize=50`
      );
      // console.log(result);
      // Si existe un resultado y el status es positivo
      if (result && mounted && result.data.status) {
        // Actualizamos la lista en la data local
        setListCategoria(result.data.list);
        // result.data.list.servicioWeb._id === id && result.data.list
      }
      // Finalizamos carga
      setLoading(false);
    };

    // Obtenemos las categorias
    // if (id) {
    getCategoria();
    // }

    // Limpiamos el montaje
    return () => {
      mounted = false;
    };
  }, []);
  // console.log(listCategoria);

  // canales
  useEffect(() => {
    // Estado inicial de montaje
    let mounted = true;
    // Función para obtener todas los canales
    const getProgramacion = async () => {
      // Iniciamos carga
      setLoading(true);
      // Obtenemos la lista de los canales con fetch
      const result = await fetchData(`web/programacion?page=1&pageSize=1000`);
      //   console.log(result);
      // Si existe un resultado y el status es positivo
      if (result && mounted && result.data.status) {
        const data = result.data.list.map(
          (ele) =>
            ele?.local?.nombre && normalizar(ele.local.nombre) === sede_ && ele
        );
        // console.log(data);
        // Actualizamos la lista en la data local
        setListProgramacion(data);
      }
      // Finalizamos carga
      setLoading(false);
    };

    // Obtenemos los canales
    // if (id) {
    getProgramacion();
    // }

    // Limpiamos el montaje
    return () => {
      mounted = false;
    };
  }, [sede_]);
  // console.log(listProgramacion);

  // //Creamos un nuevo objeto donde vamos a almacenar los servicios por categoria
  // let nuevoObjetoServiciosCategorias = {};
  // //Recorremos el arreglo
  // listProgramacion.forEach((x) => {
  //   //Si la ciudad no existe en nuevoObjeto entonces
  //   //la creamos e inicializamos el arreglo de categorias servicios
  //   if (!nuevoObjetoServiciosCategorias.hasOwnProperty(x.categoria.nombre)) {
  //     nuevoObjetoServiciosCategorias[x.categoria.nombre] = {
  //       categoriaServicios: [],
  //     };
  //   }

  //   //Agregamos los datos de servicios
  //   nuevoObjetoServiciosCategorias[x.categoria.nombre].categoriaServicios.push({
  //     logo: x.logo,
  //     numero: x.numero,
  //     _id: x._id,
  //     categoria: x.categoria._id,
  //   });
  // });

  // console.log(nuevoObjetoServiciosCategorias);
  return (
    <>
      {loading && (
        <div className="px-20 py-52">
          <ProgressBar />
        </div>
      )}
      <>
        <PageHelmet pageTitle="Programación" />

        {/* Start Header Area  */}
        <MemoryRouter>
          <Header logo="light" color="color-white" />
        </MemoryRouter>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <Breadcrumb title="Programación Bagua Grande" />
        {/* End Breadcrump Area */}

        {/* Listado de canales de Moyobamba */}
        <div className="service-area pt--0 pb--80 bg_color--1 BootstrapMulti m-5">
          {listCategoria.map((ele, index) => {
            return (
              // normalizar(ele.local.nombre).includes(value_) && (
              <div className="container" key={index}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center service-style--3 mb--40 mt--30">
                      <h2 className="title" style={{ fontSize: "35px" }}>
                        {ele.nombre}
                      </h2>
                      {/* <h2 className="title" style={{ fontSize: "35px" }}>
                      {ele._id}
                    </h2> */}
                    </div>
                  </div>
                </div>
                {listProgramacion !== null && listProgramacion.length > 0 && (
                  <Slider
                    className="carruselProgramacion flex align-items-center"
                    {...slideSlick}
                  >
                    {listProgramacion.map((value, indexS) => {
                      //   listProgramacion[index] !== "";
                      return (
                        value?.categoria?.nombre === ele?.nombre && (
                          <div className="card " key={indexS}>
                            <img
                              className="card-img-top"
                              src={value.logo}
                              alt="imagem"
                              title="imagem"
                            />
                            {value.numero !== 0 && (
                              <div
                                id="color"
                                style={{
                                  // backgroundColor: '#' + ele.color + '',
                                  backgroundColor: `#${ele.color}`,
                                  width: "100%",
                                }}
                              >
                                <h5
                                  className="align-center"
                                  style={{
                                    fontSize: "15px",
                                    marginBottom: "0px",
                                    color: "#2f21b3",
                                  }}
                                >
                                  {value.numero}
                                </h5>
                              </div>
                            )}
                          </div>
                        )
                      );
                    })}
                  </Slider>
                )}
              </div>
              // )
            );
          })}
        </div>
        {/* Fin del Listado de canales de Moyobamba */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Footer Area  */}
        <Footer />
        {/* End Footer Area  */}
      </>
    </>
  );
};
export default memo(PrograBagua);
