import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import fetchData from '../services/fetch';

const Testimonial = () => {
	const [listTestimonialAbonados, setListTestimonialAbonados] = useState([]);
	// Efecto para obtener la lista de los testimonios de los abonados
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getTestimonialAbonados = async () => {
			// Iniciamos carga de la tabla
			// setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/testimonialAbonados?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListTestimonialAbonados(result.data.list);
			}
			// // Finalizamos carga de la tabla
			// setLoading(false);
		};

		// Obtenemos  los testimonios de los abonados
		getTestimonialAbonados();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListTestimonialAbonados]);
	// console.log(listTestimonialAbonados);

	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<Tabs>
						{listTestimonialAbonados.map((testimonio, index) => (
							<TabPanel key={index}>
								<div className="rn-testimonial-content text-center">
									<div className="inner">
										<p>{testimonio.comentario}</p>
									</div>
									<div className="author-info">
										<h6>
											<span>{testimonio.nombre} </span> - {testimonio.empleo}
										</h6>
									</div>
								</div>
							</TabPanel>
						))}

						<TabList className="testimonial-thumb-wrapper">
							{listTestimonialAbonados.map((testimonio, index) => (
								<Tab key={index}>
									<div className="testimonial-thumbnail">
										<div className="thumb">
											<img src={testimonio.foto} alt="Testimonial Images" />
										</div>
									</div>
								</Tab>
							))}
						</TabList>
					</Tabs>
				</div>
			</div>
		</>
	);
};
export default Testimonial;
