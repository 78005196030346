import React, { useState, useEffect, memo } from "react";
// import { normalizar } from '../../helpers/texts';
import ModalPreciosPlanes from "./ModalPreciosPlanes";
import fetchData from "../../services/fetch";
import ProgressBar from "../Progress/ProgressCircle";

const ServiceList = ({ column, styevariation, filtro, categoria }) => {
  // Estado de apertura del modal del precios
  const [loading, setLoading] = useState(true);
  const [openMod, setOpenMod] = useState(false);
  const [listServicios, setListServicios] = useState([]);
  const [sede, setSede] = useState("");
  const [servicio, setServicio] = useState("");

  // efecto para optener los servicios
  useEffect(() => {
    // Estado inicial de montaje
    let mounted = true;
    // Función para obtener todas los servicios
    const getServicios = async () => {
      // Iniciamos carga de la tabla
      setLoading(true);
      // Obtenemos la lista de los servicios con fetch
      const result = await fetchData(
        `web/servicio/servicioWeb?page=1&pageSize=1000`
      );
      // Si existe un resultado y el status es positivo
      if (result && mounted && result.data.status) {
        // Actualizamos la lista en la data local
        setListServicios(result.data.list);
      }
      // // Finalizamos carga de la tabla
      setLoading(false);
    };

    // Obtenemos los servicios
    getServicios();

    // Limpiamos el montaje
    return () => {
      mounted = false;
    };
  }, [setListServicios]);
  // console.log(listServicios);

  // Función para abrir el modal del mapa de la address
  const handleOpenMod = (value) => {
    setOpenMod(true);
    setSede(value.servicio.barrio.local.nombre);
    setServicio(value.servicio.nombre);
    // Filtro(value);
  };

  // console.log(filtro);
  // console.log(servicio);
  // console.log(sede);
  return (
    <>
      {loading ? (
        <div className="px-20 py-52">
          <ProgressBar />
        </div>
      ) : (
        <>
          {listServicios.map((value, index) => {
            // console.log(value);
            return (
              value.categoria.nombre === categoria &&
              (filtro === "null" || filtro === null || filtro === ""
                ? value.servicio?.barrio?.local?.nombre === `MOYOBAMBA` ||
                  value.servicio?.barrio?.local?.nombre === "BAGUA" ||
                  value.servicio?.barrio?.local?.nombre === "TARAPOTO" ||
                  value.servicio?.barrio?.local?.nombre === "SORITOR" ||
                  value.servicio?.barrio?.local?.nombre === "JEPELACIO"
                : filtro === "TODOS"
                ? value.servicio?.barrio?.local?.nombre === "MOYOBAMBA" ||
                  value.servicio?.barrio?.local?.nombre === "BAGUA" ||
                  value.servicio?.barrio?.local?.nombre === "TARAPOTO" ||
                  value.servicio?.barrio?.local?.nombre === "SORITOR" ||
                  value.servicio?.barrio?.local?.nombre === "JEPELACIO"
                : value.servicio?.barrio?.local?.nombre === filtro) && (
                <div
                  className={`${column} centrar pb--80  `}
                  // style={{ marginBottom: "0px"}}
                  key={index}
                >
                  <div className={`portfolio ${styevariation}`}>
                    <div className="thumbnail-inner">
                      <img className="thumbnail" src={value.img} alt="" />
                      <img className="bg-blr-image" src={value.img} alt="" />
                    </div>
                    <div className="content">
                      <div className="inner">
                        <span>
                          <b>
                            {`${value.servicio.barrio.local.nombre} 
															`}
                          </b>
                        </span>
                        <h4 style={{ WebkitTextStroke: "1px #FD7C14" }}>
                          {value.servicio.nombre}
                        </h4>
                        {/* <h5 className="portfolio-button"> */}
                        <button
                          onClick={() => handleOpenMod(value)}
                          className="portfolio-button rn-btn btn-precios"
                          type="button"
                        >
                          Ver Precios
                        </button>

                        {/* </h5> */}

                        <div className="portfolio-button">
                          <a
                            className="rn-btn"
                            href={`/detallesServicio/banner/${value._id}`}
                          >
                            {value.textoBoton}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </>
      )}
      <ModalPreciosPlanes
        open={openMod}
        setOpen={setOpenMod}
        ServicioFilter={servicio}
        // Sede={value.servicio.barrio.local.nombre}
        Sede={sede}
        // Servicio={servicio}
      />
    </>
  );
};
export default memo(ServiceList);
