import React, { useState, useEffect } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import fetchData from '../services/fetch';

function DialogMigrate({ children, disableBackdropClick, disableEscapeKeyDown, onClose, ...rest }) {
	const handleClose = (event, reason) => {
		if (disableBackdropClick && reason === 'backdropClick') {
			return false;
		}

		if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
			return false;
		}

		if (typeof onClose === 'function') {
			onClose();
		}
		return null;
	};

	return (
		<Dialog onClose={handleClose} {...rest}>
			{children}
		</Dialog>
	);
}

const ModalLoad = props => {
	const { open, setOpen } = props;

	// const [loading, setLoading] = useState(true);

	const [modal, setModal] = useState([]);

	// Efecto para obtener la lista de los Módulos
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todos los módulos
		const getModalLoad = async () => {
			// Iniciamos carga de la tablas
			// setLoading(true);
			// Obtenemos la lista de los módulos con fetch
			const result = await fetchData(`web/modalLoad?page=1&pageSize=10`);
			//   console.log(result);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Guardamos el resultado en el estado
				setModal(result.data.list);
			}
			// Finalizamos carga de la tabla
			// setLoading(false);
		};

		// Obtenemos los módulos
		getModalLoad();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, []);
	// Función para cerrar el modal
	const handleClose = () => {
		setOpen(false);
	};
	// console.log(modal);

	return (
		<DialogMigrate
			// maxWidth="md"
			// maxHeight="md"
			open={open}
			onClose={handleClose}
			aria-labelledby="submodulos-list"
			disableBackdropClick
			disableEscapeKeyDown
			// style={{ display: "inline-block", position: "relative" }}
		>
			{modal.map(item => {
				return (
					<div key={item._id}>
						{' '}
						<IconButton
							className="closeModalLoad"
							onClick={handleClose}
							style={{
								backgroundColor: 'red',
								borderRadius: '0%',
								color: 'white'
							}}
						>
							<CloseIcon />
						</IconButton>
						<img className="inline-block imagenLoadModal" src={item.img} alt="Banner" />
					</div>
				);
			})}
		</DialogMigrate>
	);
};

ModalLoad.propTypes = {};

export default ModalLoad;
