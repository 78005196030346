import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCheck } from 'react-icons/fi';
import fetchData from '../../services/fetch';

const TabOne = props => {
	const { tabStyle } = props;
	const [listAcercaTabs, setListAcercaTabs] = useState([]);

	// efecto para optener el acerca de la empresa
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getAcercaTabs = async () => {
			// Iniciamos carga de la tabla
			// setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/inicio/acercaTabs?page=1&pageSize=100`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListAcercaTabs(result.data.list);
			}
			// // Finalizamos carga de la tabla
			// setLoading(false);
		};

		// Obtenemos los servicios
		getAcercaTabs();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListAcercaTabs]);
	// console.log(listAcercaTabs);

	return (
		<div>
			{/* Start Tabs Area */}
			<div className="tabs-area">
				<div className="container">
					<div className="row">
						{listAcercaTabs.map((tabs, index) => (
							<div className="col-lg-12" key={index}>
								<Tabs>
									<TabList className={`${tabStyle}`}>
										<Tab>{tabs.tab1}</Tab>
										<Tab>{tabs.tab2}</Tab>
										<Tab>{tabs.tab3}</Tab>
									</TabList>

									<TabPanel>
										<div className="single-tab-content">
											<p>{tabs.descripcionTab1}</p>

											<div className="mt--30">
												<h4>{tabs.subtitituloTab1}</h4>
												<ul className="list-style--1">
													{tabs.tab1Items.map((name, indexS) => {
														return (
															<li key={indexS}>
																<FiCheck /> {name}
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									</TabPanel>

									<TabPanel>
										<div className="single-tab-content">
											{tabs.descripcionTab2.map((descripcion, indexSu) => (
												<p key={indexSu}>{descripcion}</p>
											))}
										</div>
									</TabPanel>

									<TabPanel>
										<div className="single-tab-content">
											{tabs.descripcionTab3.map((descripcion, indexSup) => (
												<p key={indexSup}>{descripcion}</p>
											))}
										</div>
									</TabPanel>
								</Tabs>
							</div>
						))}
					</div>
				</div>
			</div>
			{/* End Tabs Area */}
		</div>
	);
};

export default TabOne;
