// Importamos las dependencias //

import React, { forwardRef, memo, useState, useEffect } from 'react';
import {
	Dialog,
	Slide,
	// useMediaQuery,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	// useTheme,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@material-ui/core';
import fetchData from '../../services/fetch';
import ProgressBar from '../Progress/ProgressCircle';
import { normalizar } from '../../helpers/texts';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

// Definimos la Vista del componente abonado -Modal Map //

const ModalPreciosPlanes = props => {
	// props
	const { open, setOpen, ServicioFilter, Sede } = props;

	const [loading, setLoading] = useState(true);

	const [listServicios, setListServicios] = useState([]);

	// efecto para optener los servicios
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		const getServicios = async () => {
			// Iniciamos carga de la tabla
			setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`servicios/servicio?page=1&pageSize=1000`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				const service = await normalizar(ServicioFilter);
				const sede = await normalizar(Sede);
				// listamos los servicios que coinciden con el nombre del servicio y con la sede
				const array = result.data.list
					.map(ele => ele)
					.filter(ele => {
						// console.log(ele);
						return normalizar(ele.nombre) === service && normalizar(ele.barrio.local.nombre) === sede;
					});

				const filter = await Promise.all(array);
				setListServicios(filter);
			}
			// // Finalizamos carga de la tabla
			setLoading(false);
		};

		// Obtenemos los servicios
		getServicios(mounted);

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [ServicioFilter, Sede, setLoading, setListServicios]);

	// console.log(listServicios);
	// console.log(Sede);
	// console.log(ServicioFilter);

	// funcion para cerrar el modal
	const handleClose = () => {
		setOpen(false);
	};
	// console.log(ServiceListContent);
	// rednderizamos el componente
	return (
		<>
			{loading ? (
				<div className="px-20 py-52">
					<ProgressBar />
				</div>
			) : (
				<Dialog
					//   style={{ position: "absolute", zIndex: 50 }}
					// fullScreen={fullScreen}
					maxWidth={false}
					open={open}
					TransitionComponent={Transition}
					onClose={(event, reason) => {
						if (reason !== 'backdropClick') {
							onclose(event, reason);
						}
					}}
					aria-labelledby="mapa-address"
					disableEscapeKeyDown
				>
					<DialogTitle>
						<div className="flex flex-row justify-between">
							<div className="font-600">
								Precio del Servicio de {ServicioFilter} en {Sede}
							</div>
							{/* <div
          // style={{ position: "absolute", zIndex: 100, right: "50px" }}
          ></div> */}
						</div>
					</DialogTitle>
					<DialogContent>
						<div className="container">
							<Table className="min-w-xl" aria-labelledby="tableTitle">
								<TableBody>
									<TableRow className="h-64">
										<TableCell className="font-extrabold">Sede</TableCell>
										<TableCell className="font-extrabold">Barrio</TableCell>
										<TableCell className="font-extrabold">Precio</TableCell>
									</TableRow>
									{listServicios.map((val, index) => (
										<TableRow className="h-32" hover tabIndex={-1} key={index}>
											<TableCell className="py-2">{val.barrio.local.nombre}</TableCell>
											<TableCell className="py-2">{val.barrio.nombre}</TableCell>
											<TableCell className="py-2">{val.costo}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</DialogContent>

					<DialogActions>
						<div className="my-6">
							<Button onClick={handleClose} color="primary">
								Cerrar
							</Button>
						</div>
					</DialogActions>
					{/* autofocus={autofocus} */}
				</Dialog>
			)}
		</>
	);
};

export default memo(ModalPreciosPlanes);
