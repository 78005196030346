import React, { useState, useEffect } from 'react';
import fetchData from '../../services/fetch';

const PortfolioList = props => {
	const { column, styevariation } = props;
	const [listServicios, setListServicios] = useState([]);
	const list = listServicios.slice(0, props.item);
	// efecto para optener los servicios
	useEffect(() => {
		// Estado inicial de montaje
		let mounted = true;
		// Función para obtener todas los servicios
		const getServicios = async () => {
			// Iniciamos carga de la tabla
			// setLoading(true);
			// Obtenemos la lista de los servicios con fetch
			const result = await fetchData(`web/servicio/servicioWeb?page=1&pageSize=1000`);
			// Si existe un resultado y el status es positivo
			if (result && mounted && result.data.status) {
				// Actualizamos la lista en la data local
				setListServicios(result.data.list);
			}
			// // Finalizamos carga de la tabla
			// setLoading(false);
		};

		// Obtenemos los servicios
		getServicios();

		// Limpiamos el montaje
		return () => {
			mounted = false;
		};
	}, [setListServicios]);
	// console.log(listServicios);
	return (
		<>
			{list.map((value, index) => (
				<div className={`${column} `} key={index}>
					<div className={`portfolio ${styevariation}`}>
						<div className="thumbnail-inner">
							<img className="thumbnail" src={value.img} alt="" />
							<img className="bg-blr-image" src={value.img} alt="" />
							{/* <div className={`thumbnail ${value.img}`}></div>
              <div className={`bg-blr-image ${value.img}`}></div> */}
						</div>
						<div className="content">
							<div className="inner">
								<b>{value.servicio.barrio.local.nombre} </b>

								{/* <p>{value.categoria.nombre}</p> */}
								<h4 style={{ WebkitTextStroke: '1px #FD7C14' }}>{value.servicio.nombre}</h4>
								<div className="portfolio-button">
									<a className="rn-btn" href={`/detallesServicio/banner/${value._id}`}>
										{value.textoBoton}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
};
export default PortfolioList;
