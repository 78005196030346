// Función para capitalizar un texto //

export const capitalizar = text => {
	// Tamaño del texto
	const size = text.length;
	// Convertimos todo a minúscula
	const text_ = text.toLowerCase();
	// Parte capitalizada
	const textC = text_.substring(0, 1).trim().toUpperCase();
	// Parte minúscula
	const textm = text_.substring(1, size).trim();
	return textC + textm;
};

// Función para normalizar un texto //

export const normalizar = text => {
	// Normalizamos de acuerdo a criterio
	const normTxt =
		typeof text === 'string'
			? text.toLowerCase()
			: ''
					.toLowerCase() // Convertimos a minúsculas
					.normalize('NFD') // Forma de Normalización de Descomposición Canónica
					.replace(/[\u0300-\u0302]/g, '') // Removemos los acentos
					.trim(); // Quitamos los espacios
	return normTxt;
};
